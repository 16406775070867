/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import downloadFile from './HiSG_report.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "HiSG - Flutter AR Mobile App"), "\n", React.createElement(_components.p, null, "HiSG is an Android mobile application created by Haoran as the outcome of final year project at university. The app is designed to benefit tourists and boost tourism in Singapore by integration with Augumented Reality and 360 degree experiences."), "\n", React.createElement(_components.h2, null, "Tech Stack"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "App Framework: ", React.createElement(_components.a, {
    href: "https://flutter.dev/"
  }, "Flutter")), "\n", React.createElement(_components.li, null, "AR Feature Plugin: ", React.createElement(_components.a, {
    href: "https://www.wikitude.com/"
  }, "Wikitude")), "\n", React.createElement(_components.li, null, "Languages: Dart, HTML, CSS, JavaScript"), "\n", React.createElement(_components.li, null, "Cloud Service Provider: ", React.createElement(_components.a, {
    href: "https://cloud.google.com/"
  }, "Google Cloud Platform")), "\n", React.createElement(_components.li, null, "Authentication & Database: ", React.createElement(_components.a, {
    href: "https://firebase.google.com/"
  }, "Firebase")), "\n", React.createElement(_components.li, null, "APIs:  ", React.createElement(_components.a, {
    href: "https://mapsplatform.google.com/"
  }, "Google Maps Platform"), " ,  ", React.createElement(_components.a, {
    href: "https://tih.stb.gov.sg/content/tih/en/about_tih/about-us.html"
  }, "Tourism Information & Services Hub")), "\n", React.createElement(_components.li, null, "Data Collection by Web Scrapping: Python, ", React.createElement(_components.a, {
    href: "https://www.selenium.dev/"
  }, "Selenium")), "\n"), "\n", React.createElement(_components.h2, null, "Key Features"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "AR nearby points of interest view"), "\n", React.createElement(_components.li, null, "AR walking navigation"), "\n", React.createElement(_components.li, null, "360 degree photos and videos experiences"), "\n", React.createElement(_components.li, null, "Keyword search among places, hotels, events, precincts, tours, 360 experiences... and many more in Singapore"), "\n", React.createElement(_components.li, null, "Trip planner and recommendation engine to manage personalized itineraries"), "\n"), "\n", React.createElement(_components.h2, null, "Other Features"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Authentication: register or login with email & password or Google"), "\n", React.createElement(_components.li, null, "Save activity or destination from search result to Favorites list"), "\n", React.createElement(_components.li, null, "Check lines and stations for Singapore MRT"), "\n"), "\n", React.createElement(_components.h2, null, "Demo Video"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: "https://www.youtube.com/watch?v=6vSq-pMynyI"
  }, React.createElement("i", {
    class: "fa-brands fa-youtube fa-2x"
  }))), React.createElement("span", null, React.createElement("a", {
    href: "https://www.bilibili.com/video/BV1Yu411e72U"
  }, React.createElement("i", {
    class: "fa-brands fa-bilibili fa-2x"
  })))), "\n", React.createElement(_components.h2, null, "Detailed Report"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: downloadFile,
    download: true
  }, React.createElement("i", {
    class: "fa-solid fa-file-arrow-down fa-2x"
  })))), "\n", React.createElement(_components.h2, null, "Source Code"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: "https://github.com/Haoran101/FYP-HiSG"
  }, React.createElement("i", {
    class: "fa-brands fa-github fa-2x"
  })))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
